/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v15-latin-regular-eot.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('../fonts/open-sans-v15-latin-regular-eot.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v15-latin-regular-woff2.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v15-latin-regular-woff.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v15-latin-regular-ttf.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v15-latin-regular-svg.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/open-sans-v15-latin-600-eot.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
       url('../fonts/open-sans-v15-latin-600-eot.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v15-latin-600-woff2.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v15-latin-600-woff.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v15-latin-600-ttf.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v15-latin-600-svg.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* lintel */
@font-face {
  font-family: 'Lintel';
  font-style: normal;
  font-weight: 400;
  src: local('Lintel Regular'), local('Lintel-Regular'),
       url('../fonts/lintel-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lintel-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lintel-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lintel-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lintel-regular.svg#Lintel-Regular') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Lintel';
  font-style: italic;
  font-weight: 400;
  src: local('Lintel Italic'), local('Lintel-Italic'),
       url('../fonts/lintel-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lintel-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lintel-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lintel-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lintel-italic.svg#Lintel-Italic') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Lintel';
  font-style: normal;
  font-weight: 600;
  src: local('Lintel Medium'), local('Lintel-Medium'),
       url('../fonts/lintel-medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lintel-medium.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lintel-medium.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lintel-medium.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lintel-medium.svg#Lintel-Medium') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Lintel';
  font-style: normal;
  font-weight: 700;
  src: local('Lintel Bold'), local('Lintel-Bold'),
       url('../fonts/lintel-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lintel-bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lintel-bold.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lintel-bold.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lintel-bold.svg#Lintel-Bold') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Lintel';
  font-style: normal;
  font-weight: 800;
  src: local('Lintel Heavy'), local('Lintel-Heavy'),
       url('../fonts/lintel-heavy.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lintel-heavy.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lintel-heavy.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lintel-heavy.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lintel-heavy.svg#Lintel-Heavy') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: local('Oswald Light'), local('Oswald-Light'),
       url('../fonts/oswald-light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/oswald-light.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/oswald-light.woff') format('woff'), /* Modern Browsers */
       url('../fonts/oswald-light.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/oswald-light.svg#Oswald-Light') format('svg'); /* Legacy iOS */
}