@import "utilities/fonts";
@import "utilities/variables";

@media print {
	.top-left-up,
	.top-left-up.bottom-left-down,
	.top-left-up.bottom-right-down,
	.top-left-down,
	.top-left-down.bottom-left-up,
	.top-left-down.bottom-right-up,
	.top-right-up,
	.top-right-up.bottom-left-down,
	.top-right-up.bottom-right-down,
	.top-right-down,
	.top-right-down.bottom-left-up,
	.top-right-down.bottom-right-up,
	.bottom-left-down,
	.bottom-right-down {
	    clip-path: none;
	}

	.main {
		li {
			&:before,
			&:after {
				content: none;
			}
		}
	}

	html {
		color: $black;
	}

	.text-slice {
		padding: 0;
	}

	.text-image {

		li {

			&:before,
			&:after {
				content: none;
			}
		}
	}

	.mask-bg {
		color: $black;
	}

	.header {
		.header__bar {


			.icon-logo {
				fill: $black;
			}
		}
		margin-bottom: 32px;
	}

	.hero {
		height: auto;
		top: 0;
		margin-bottom: 0;
		clip-path: none;

		.hero-breadcrumbs {
			top: 0;
			margin-bottom: 16px;

			ul {

				li {
					color: $black;
				}
			}
		}

		.hero-slider {
			overflow: initial;

			.hero-slide {
				background-image: none !important;

				&:before {
					content: none;
				}

				.hero-slide_content {
					position: static;
					top: 0;
					transform: none;

					h1 {
						color: $black;
					}

					.tag {
						color: $black;
						background: $black;
					}
				}
			}
		}
	}

	.content--slider {

		.content--slider_slide {
			position: static !important;
			display: block !important;
			padding: 16px 0;

			p {
				visibility: initial;
			}
		}
	}

	.testimonials-section {
		.testimonials-slider {
			.testimonials-slider_slide {
				position: static !important;
				display: block !important;
				margin: 0 auto;
				padding: 16px 0;
				visibility: initial !important;
			}		
		}
	} 

	.experts-slider {
		width: auto;
		overflow: initial;

		.experts-slide {
			display: inline !important;
			width: 50%;
		}
	}

	.testimonials,
	.image-slice,
	.text-slice {
		background-color: $white;
	}

	.main {
		ul {
			list-style-type: initial;
		}

		li {
			margin-left: 16px;
			padding-left: 16px;
			padding-bottom: 16px;
		}
	}

	.contact,
	.section--menu,
	.social-share__list,
	.video-slice,
	.new-video_slice,
	.image-slice,
	.new-image_slice,
	.overlay-1,
	.overlay-2,
	.controls,
	.ui-card,
	.showcase,
	.spacer--x-large,
	.inpage-nav,
	.stories-row,
	.pagination,
	.related-insights,
	.testimonials-slide-number,
	.upcoming-events-row,
	.related--articles__list,
	.footer .footer__social,
	.footer .footer__copyright--desktop,
	.footer .footer-title,
	.footer .footer-list,
	.footer .large-2 {
		display: none;
	}
}